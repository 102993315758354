<template>
  <v-menu
    offset-y
    nudge-bottom="22"
    min-width="175"
    left
    :elevation="$vuetify.theme.dark ? 9 : 8"
  >
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="transparent"
        dark
        v-bind="attrs"
        small
        v-on="on"
      >
        <v-avatar
          tile
          size="14"
          class="mr-2"
        >
          <img
            :src="require(`@/assets/images/flags/${$i18n.locale || 'fr'}.png`)"
            alt=""
          >
        </v-avatar>
        <span>{{ $i18n.locale || 'fr' }}</span>
        <v-icon
          right
          small
        >
          mdi-chevron-down
        </v-icon>
      </v-btn>
    </template>

    <!-- Options List -->
    <v-list>
      <v-list-item-group
        :value="$i18n.locale"
        @change="updateActiveLocale"
      >
        <v-list-item
          v-for="locale in locales"
          :key="locale.locale"
          :value="locale.locale"
        >
          <v-img
            :src="locale.img"
            height="14px"
            width="22px"
            :alt="locale.locale"
            class="me-2"
          />
          <v-list-item-title>{{ locale.title }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
  import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'

  import { directive as onClickaway } from 'vue-clickaway'
  import { i18n } from '@/plugins/i18n'

  export default {
    name: 'AppBarI18n',
    directives: {
      onClickaway: onClickaway,
    },
    components: {
    },
    data: () => ({
      locales: [
        {
          title: 'English',
          img: require('@/assets/images/flags/en.png'),
          locale: 'en',
        },
        {
          title: 'French',
          img: require('@/assets/images/flags/fr.png'),
          locale: 'fr',
        },
      ],
    }),

    computed: {

    },
    created () {
      const lang = window.localStorage.getItem('locale')
      if (lang !== undefined && lang === 'undefined') {
        this.$i18n.locale = lang || 'fr'
      }
    },
    methods: {
      ...mapMutations(['setValue']),
      updateActiveLocale (locale) {
        // Set to RTL mode if locale is arabic
        // isRtl.value = locale === 'ar'

        this.$i18n.locale = locale
        this.setValue({ type: 'locale', value: locale })
        window.localStorage.setItem('locale', locale)
        window.location.reload()
        /* axios.get('/home/' + locale).then(() => {

        }) */
      },

    },
  }
</script>
