<template>
  <v-menu
    offset-y
    left
    nudge-bottom="22"
    :elevation="$vuetify.theme.dark ? 9 : 8"
    content-class="list-style notification-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        v-if="$store.state.login && unreadNotifications.length > 0"
        color="error"
        :content="unreadNotifications.length === 0 ? '0' : (unreadNotifications.length>9?'9+':unreadNotifications.length)"
        overlap
        @click="inboxClicked"
      >
        <v-btn icon
               v-bind="attrs"
               v-on="on" x-small>
          <v-icon
            class="mx-2 cursor-pointer"
            color="white"
          >
            mdi-bell-outline
          </v-icon>
        </v-btn>

      </v-badge>
    </template>
    <v-card class="app-bar-notification-content-container">
      <perfect-scrollbar
        class="ps-user-notifications"
        :options="perfectScrollbarOptions"
      >
        <v-list class="py-0">
          <!-- Header -->
          <v-list-item
            class="d-flex"
            link
          >
            <div class="d-flex align-center justify-space-between flex-grow-1">
              <span class="font-weight-semibold">{{ $t("Notifications") }}</span>
              <v-chip
                class="v-chip-light-bg primary--text font-weight-semibold"
                small
              >
                {{unreadNotifications.length === 0 ? '0' : (unreadNotifications.length>9?'9+':unreadNotifications.length)}}
                {{ $t("Nouveaux") }}
              </v-chip>
            </div>
          </v-list-item>
          <v-divider />

          <!-- Notifications -->
          <template v-for="(notification, index) in notifications">
            <v-list-item
              :key="notification.title"
              link
            >
              <!-- Avatar -->
              <v-list-item-avatar
                :class="[{'v-avatar-light-bg primary--text justify-center': !notification.avatar}]"
                size="38"
              >
                <v-img
                  v-if="notification.avatar"
                  :src="notification.avatar"
                />
                <span
                  v-else-if="!notification.avatar"
                  class="text-lg"
                >{{ getInitialName(notification.title) }}</span>
                <v-img
                  v-else
                  :src="notification.avatar"
                />
              </v-list-item-avatar>

              <!-- Content -->
              <v-list-item-content class="d-block">
                <v-list-item-title class="text-sm font-weight-semibold">
                  {{ notification.title }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-sm">
                  {{ notification.headline }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <!-- Item Action/Time -->
              <v-list-item-action>
                <span class="text--secondary text-xs">{{ formatDate(notification.created_at) }}</span>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="index" />
          </template>
          <v-list-item
            key="read-all-btn"
            class="read-all-btn-list-item"
          >
            <v-btn
              block
              color="primary"
              to="/notifications"
            >
              {{ $t("Lire toutes les notifications") }}
            </v-btn>
          </v-list-item>
        </v-list>
      </perfect-scrollbar>
    </v-card>
  </v-menu>
</template>
<script>
  import { mapGetters, mapActions, mapState } from 'vuex'

  // 3rd Party
  import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
  import { directive as onClickaway } from 'vue-clickaway'
  import { i18n } from '@/plugins/i18n'

  export default {
    name: 'AppBarNotifications',
    directives: {
      onClickaway: onClickaway,
    },
    components: {
      // 3rd Party
      PerfectScrollbar,
    },
    data: () => ({
      not_clicked: true,
      /* notifications: [
        {
          user: {
            avatar: require('@/assets/images/avatars/4.png'),
            name: 'Flora Downey',
          },
          title: 'Congratulation John! 🎉 ',
          subtitle: 'Won the monthly best seller badge',
          time: 'Today',
        },
        {
          user: {
            avatar: '',
            name: 'Tom Holland',
          },
          title: 'New user registered.',
          subtitle: '5 hours ago',
          time: 'Yesterday',
        },
        {
          user: {
            avatar: require('@/assets/images/avatars/5.png'),
            name: 'Bertram Gilfoyle',
          },
          title: 'New message received',
          subtitle: 'You have 10 unread messages',
          time: '11 Aug',
        },
        {
          service: {
            icon: require('@/assets/images/svg/paypal.svg'),
          },
          title: 'Paypal',
          subtitle: 'Received Payment',
          time: '25 May',
        },
        {
          user: {
            avatar: require('@/assets/images/avatars/3.png'),
            name: 'John Smith',
          },
          title: 'Revised Order 📦',
          subtitle: 'New order revised from john',
          time: '19 Mar',
        },
        {
          service: {
            icon: require('@/assets/images/svg/chart.svg'),
          },
          title: 'Finance report has been generated',
          subtitle: '25 hrs ago',
          time: '27 Dec',
        },
      ], */
      perfectScrollbarOptions: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }),

    computed: {
      ...mapGetters(['getCartProducts', 'unreadNotifications']),
      ...mapState({
        user: 'user',
        login: 'login',
        notifications: 'notifications',
      }),
      unreadNotifications () {
        return this.notifications.filter((e) => !e.isOpened)
      },
    },

    methods: {
      inboxClicked () {
        // eslint-disable-next-line no-undef
        axios.get('notifications/read')
        this.notifications.map((item) => {
          item.isOpened = true
          item.read_at = new Date()
        })
        this.not_clicked = false
      },
      markRead (notification) {

      },
      updateActiveLocale (locale) {
        // Set to RTL mode if locale is arabic
        // isRtl.value = locale === 'ar'

        this.$i18n.locale = locale
      },
      getInitialName: (fullName) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        fullName
          .split(' ')
          .map(n => n[0])
          .join(''),

    },
  }
</script>
