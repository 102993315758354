var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"searchForm",attrs:{"action":"/search"}},[_c('div',{staticClass:"search-bar d-flex p-relative"},[_c('v-combobox',{attrs:{"name":"query","placeholder":_vm.$t('Entrez le mot-clé ici . . .'),"filled":"","rounded":"","dense":"","multiple":false,"loading":_vm.loading,"prepend-inner-icon":"mdi-magnify","items":_vm.items,"search-input":_vm.search},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$refs.searchForm.submit()}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('span',{staticClass:"subheading"},[_vm._v(_vm._s(_vm.search && _vm.search.length > 0?(_vm.loading?_vm.$t('Chargement...') : _vm.$t('Pas de données disponibles pour') + " '" + _vm.search + "'"): _vm.$t('Tapez un terme de recherche dans le champ pour effectuer une recherche.')))])])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{attrs:{"to":(_vm.searchType.value === 'articles'?("/products/" + (item.slug)):("/shops/" + (item.id)))}},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.searchType.value === 'articles'?item.image:item.avatar_thumb || '@/assets/images/logo-mini.png',"lazy-src":require("@/assets/images/logo-mini.png")}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-truncate",domProps:{"innerHTML":_vm._s(_vm.searchType.value === 'articles'?item.name:item.pseudo)}})],1)],1)]}}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"search-bar-dropdown"},[(_vm.searchType.value === 'articles')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize dropdown",attrs:{"color":"white"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.selectedCategory?_vm.selectedCategory.name : _vm.$t('Catégories'))+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,false,2579147697)},[_c('v-list',_vm._l((_vm.$store.state.categories),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.cancelsearch;_vm.selectedCategory = item}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize dropdown",attrs:{"color":"white"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.searchType ? _vm.searchType.name : _vm.$t('Articles'))+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.searchTypes),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.searchType = item;_vm.find}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }